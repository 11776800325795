import Ajv from 'ajv/dist/ajv.min.js'

import { validate as validateVin } from 'vin-validator'
import { validatePhone } from './validators'

import cloneDeep from 'lodash/cloneDeep'
import omitBy from 'lodash/omitBy'
import get from 'lodash/get'
import isObject from 'lodash/isObject'
import isNil from 'lodash/isNil'

/**
 * JSON schema validator
 *
 * Used package:
 * https://ajv.js.org/
 * https://github.com/epoberezkin/ajv
 *
 * For localization purposes:
 * https://github.com/epoberezkin/ajv-i18n
 *
 * About JSON schema:
 * http://json-schema.org/
 * http://json-schema.org/understanding-json-schema/
 *
 * In addition, you can use custom $translate property. Examples:
 *
 * 1. Use 'SOME.TR_ID' for all keywords:
 * 1.1.:
 * $translate: 'SOME.TR_ID'
 *
 * 1.2.:
 * $translate: {
 *   id: 'SOME.TR_ID',
 *   params: {},
 * }
 *
 * 2. Use 'SOME.TR_ID' for determined keyword only (e.g.: 'const'):
 * 2.1.:
 * $translate: {
 *   const: 'SOME.TR_ID',
 * }
 *
 * 2.2.:
 * $translate: {
 *   const: {
 *     id: 'SOME.TR_ID',
 *     params: {},
 *   }
 * }
 */

/**
 * JSON_SCHEMA_ERRORS are currently heavily depend on AJV:
 * https://github.com/epoberezkin/ajv
 *
 * All the AJV keys are available in the sources:
 * https://github.com/epoberezkin/ajv-i18n
 *
 * Some of the keys may be missing here.
 */

class JsonSchemaValidator {
  constructor () {
    this._ajv = new Ajv({
      formats: {
        vin: validateVin,
        youTubeLink: /(?:youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/,
        name: /^(?:[^#&<>"~;$^%?*=_+:/|{}()!@`[\]\\\s]\s*){1,30}$/,
        password: /^.{8,64}$/,
        phone: validatePhone,
        location: /^(\d+):(\d+):(\d+)$/,
        secondhandDealPermissionNumber: /^[\dA-Za-z]{1,12}$/,
        delId: /^\d{8}$/,
        delIds: /^\d{8}$/
      },
      allErrors: true,
      ownProperties: true,
      $data: true
    })
  }

  addSchema (name, schema) {
    this._ajv.addSchema(schema, name)
  }

  removeSchema (name) {
    this._ajv.removeSchema(name)
  }

  /**
   * Validate the object against the provided schema.
   *
   * @param {string|JsonSchema} schema Schema to validate against
   * If a string provided the scheme will be validated against a schema
   * previously provided with .addSchema()
   *
   * @param {*} anything Something to validate
   */
  validate (schema, anything) {
    const validate = this._ajv.compile(schema)

    const isValid = validate(this._adapt(anything, schema))

    if (isValid instanceof Promise) {
      return isValid
        // eslint-disable-next-line promise/prefer-await-to-then
        .then(this._craftSuccess)
        .catch(this._craftError)
    }

    if (isValid) {
      return this._craftSuccess()
    }

    return this._craftError(validate)
  }

  _craftSuccess () {
    return new JsonSchemaValidatorResult({ isValid: true })
  }

  _craftError ({ errors, schema }) {
    return new JsonSchemaValidatorResult({ isValid: false, errors, schema })
  }

  _adapt (anything, schema) {
    let result = cloneDeep(anything)
    let subSchemasProperties = this._extractSubSchemasProperties(schema)

    for (let properties of [schema.properties, ...subSchemasProperties]) {
      // try to convert values to numbers if they have 'number' type in
      // the schema
      if (properties && typeof result === 'object') {
        for (const [prop, rules] of Object.entries(properties)) {
          result[prop] = this._tryCast(result[prop], rules.type)
        }
      }
    }

    // clean empty properties, so the "required" rules work for them
    return omitBy(result, item => item === '' || isNil(item))
  }

  _extractSubSchemasProperties (schema) {
    // TODO: not all properties are extracted here
    const result = []

    if (schema.allOf) {
      for (let subSchema of schema.allOf) {
        if (subSchema.properties) {
          result.push(subSchema.properties)
        }
        if (subSchema['then'] && subSchema['then'].properties) {
          result.push(subSchema['then'].properties)
        }
        if (subSchema.else && subSchema.else.properties) {
          result.push(subSchema.else.properties)
        }
      }
    }

    return result
  }

  // returns the value of the same type if cast failed
  _tryCast (value, toType) {
    const toNum = v => v === '' || isNaN(v) ? '' : Number(v)
    const toStr = v => !v ? '' : String(v)
    const checkNum = v => typeof v === 'number'
    const kit = {
      'number': {
        cast: toNum,
        check: checkNum,
      },
      'integer': {
        cast: toNum,
        check: checkNum,
      },
      'boolean': {
        cast: v => ({ 'false': false, 'true': true }[v] || v),
        check: v => typeof v === 'boolean',
      },
      'string': {
        cast: toStr,
        check: v => typeof v === 'string',
      },
    }[toType]

    if (!kit) {
      return value
    }

    const casted = kit.cast(value)
    return kit.check(casted) ? casted : value
  }
}

class JsonSchemaValidatorResult {
  constructor ({ isValid, errors = [], schema }) {
    this.isValid = isValid
    this.errors = errors.map(this._mapError(schema))
  }

  _mapError (schema) {
    return jsvError => this._extractCustomTranslationId(schema, jsvError)
    // TODO: should return JsvError instance
  }

  _extractCustomTranslationId (schema, jsvError) {
    const error = jsvError || {}
    let translationId = ''
    let translationParams = {}

    const jsonPointer = error.schemaPath
      .replace(new RegExp(`/${error.keyword}$`), '/$translate')
    const translateSchema = get(schema, jsonPointerToPath(jsonPointer), {})

    if (typeof translateSchema === 'string') {
      translationId = translateSchema
    } else if (isObject(translateSchema)) {
      const translateSchemaByKeyword = translateSchema[error.keyword]
      if (translateSchema.id) {
        translationId = translateSchema.id
        translationParams = translateSchema.params
      } else if (typeof translateSchemaByKeyword === 'string') {
        translationId = translateSchemaByKeyword
      } else if (isObject(translateSchemaByKeyword)) {
        translationId = translateSchemaByKeyword.id
        translationParams = translateSchemaByKeyword.params
      }
    }

    return {
      ...error,
      translationId: translationId || '',
      translationParams: translationParams || {},
    }
  }

  byField () {
    const result = {}

    for (const error of this.errors || []) {
      const keyExtractorRe = /^(?:\.|\[')?([^\s'"]+)('])?$/
      let key = (keyExtractorRe.exec(error.dataPath) || [])[1]
      if (!key) {
        key = error.params.missingProperty || 'UNABLE_TO_FIND_FIELD_NAME'
      }

      (result[key] = result[key] || []).push(error)
    }

    return result
  }
}

function jsonPointerToPath (jsonPointer = '') {
  return jsonPointer
    .replace(/^#\//, '')
    .replace(/\//g, '.')
    .replace(/.(\d+)/g, '[$1]')
}

export const jsv = new JsonSchemaValidator()
